import React from "react"
import { useTranslation } from "react-i18next"
import Icon1 from "../../../images/icon 1.png"
import Icon2 from "../../../images/icon 2.png"
import Icon3 from "../../../images/icon 3.png"
import Icon4 from "../../../images/icon 4.png"
import Icon5 from "../../../images/icon 5.png"
import Section from "../../Section/Section"

import "./WhyDayra.scss"

export default function WhyDayra() {
  const { t } = useTranslation()

  return (
    <div className="why-dayra text-left">
      <Section>
        <h2 id="dayra">{t("Dayra.title")}</h2>
        <h3>{t("Dayra.subtitle")}</h3>
        <div className="item item-1">
          <img
            width="80"
            height="80"
            src={Icon1}
            alt="Hand grasping phone icon"
            className="icon"
          ></img>
          <h4>{t("Dayra.point_1")}</h4>
          <p>{t("Dayra.point_desc_1")}</p>
        </div>
        <div className="item item-2">
          <img
            src={Icon2}
            width="80"
            height="80"
            alt="Desktop screen icon"
            className="icon"
          ></img>
          <h4>{t("Dayra.point_2")}</h4>
          <p>{t("Dayra.point_desc_2")}</p>
        </div>
        <div className="item item-3">
          <img
            src={Icon3}
            width="80"
            height="80"
            alt="Stock market icon"
            className="icon"
          ></img>
          <h4>{t("Dayra.point_3")}</h4>
          <p>{t("Dayra.point_desc_3")}</p>
        </div>
        <div className="item item-4">
          <img
            src={Icon4}
            width="80"
            height="80"
            alt="Approval document icon"
            className="icon"
          ></img>
          <h4>{t("Dayra.point_4")}</h4>
          <p>{t("Dayra.point_desc_4")}</p>
        </div>
        <div className="item item-5">
          <img
            src={Icon5}
            width="80"
            height="80"
            alt="Stack of cash icon"
            className="icon"
          ></img>
          <h4>{t("Dayra.point_5")}</h4>
          <p>{t("Dayra.point_desc_5")}</p>
        </div>
      </Section>
    </div>
  )
}
